
import { BillingModel } from '@/models/Plan';
import { handleErrorUI } from '@/util/error';
import AddOnSelection from '@/components/Management/Plan/AddOnSelection.vue';

export default {
  components: { AddOnSelection },
  data() {
    return {
      loading: false,
      addOns: [],
      billingModel: BillingModel.MONTHLY
    };
  },
  mounted() {
    this.loadAddOns();
  },
  methods: {
    handleChangedBilling(billingModel: BillingModel) {
      this.billingModel = billingModel;
      this.loadAddOns();
    },
    async loadAddOns() {
      this.loading = true;
      try {
        const { addOns } = await this.$repo.plan.getAddOns(this.billingModel);
        this.addOns = addOns;
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    redirectToPlanManagement() {
      this.$router.push({
        name: 'management:plan'
      });
    }
  }
};
